<template>
  <div id="main-container">
    <!-- Hero -->
    <div class="bg-body-light">
      <div class="content content-full">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center py-2">
          <div class="flex-grow-1">
            <h1 class="h3 fw-bold mb-2">
              Dashboard
            </h1>
            <h2 class="fs-base lh-base fw-medium text-muted mb-0">
              Selamat Datang <strong>{{user.name}}</strong>.
            </h2>
          </div>
        </div>
      </div>
    </div>
    <!-- END Hero -->

    <div class="content">
      <div class="row">
        <div class="col-12 col-md-12">
          <statistics :user="user"/>
          <!-- <performance/> -->
        </div>
        <!-- <div class="col-12 col-md-3">
          <new-purchase :sales="user.sales"/>
          <new-review class="mt-5"/>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import statistics from '@/components/dashboard/statistics.vue';
// import performance from '@/components/dashboard/performance.vue';
// import NewPurchase from '@/components/dashboard/NewPurchase.vue';
// import NewReview from '@/components/dashboard/NewReview.vue';
export default {
  components: { 
    statistics,
    // performance,
    // NewPurchase,
    // NewReview
  },

  data: () => ({
    user: [],
  }),
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading_data = true;

      axios
        .get("dashboardStatistic", {
          headers: {
            "Access-Control-Allow-Headers": "*",
            "Content-Type": "application/json",
            "Accept": "application/json"
          },
        })
        .then((response) => {
          this.user = response.data.data.user;
          // console.log(this.user);
        })
        .catch((error) => console.log(error))
        .finally(() => (this.loading_data = false));
    },
  },
};
</script>

<style scoped>
@import '../../../public/oneui/assets/css/oneui.min.css';
</style>